<template>
  <div>
    <v-tabs v-model="tabSelect" grow>
      <v-tab href="#tab-1">
        <v-icon left>mdi-account-multiple</v-icon>
        Matching Agent Config
      </v-tab>
      <v-tab href="#tab-2">
        <v-icon left>mdi-marker-check</v-icon>
        Matching Tags and Sources Config
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabSelect">
      <v-tab-item value="tab-1">
        <v-card elevation="0">
          <div class="d-flex px-2">
            <v-switch inset v-model="byrange" label="BY RANGE"></v-switch>
          </div>
          <v-row no-gutters class="mt-2 px-2">
            <template v-if="byrange">
              <v-col cols="12" sm="3" class="pr-1">
                <v-select
                  v-model="dayFrom"
                  label="From"
                  :items="days"
                  :loading="loading"
                  hide-details
                  clearable
                  outlined
                  dense
                />
              </v-col>

              <v-col cols="12" sm="3" class="pr-2">
                <v-select
                  v-model="dayTo"
                  label="To"
                  :items="days"
                  :loading="loading"
                  hide-details
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="daysSelecteds"
                  label="Days"
                  multiple
                  :items="days"
                  :loading="loading"
                  hide-details
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </template>

            <v-spacer></v-spacer>
            <v-col cols="12" sm="5">
              <v-select
                v-model="employeeUuidts"
                label="Employees"
                :items="workers"
                prepend-inner-icon="mdi-doctor"
                item-text="fullname"
                item-value="uuid"
                :loading="loading"
                multiple
                hide-details
                clearable
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="1" class="pa-2">
              <v-btn
                small
                :color="uuidSettings ? 'info' : 'success'"
                @click="addConfig"
                :disabled="!validAdd || loading"
                :loading="loading"
                >{{ uuidSettings ? "Update" : "Create" }}</v-btn
              >
            </v-col>
          </v-row>
          <div class="d-flex justify-end my-1 px-4 mx-4">
            <v-btn
              text
              small
              :loading="loading"
              :disabled="loading"
              class="mx-4"
              color="primary"
              @click="getSettings"
              ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
            >
          </div>
          <!--      <v-data-iterator
      :items="itemsValueDays"
      hide-default-footer
    ></v-data-iterator> -->
          <!--    {{ objectValueDays }} -->
        </v-card>
        <div class="d-flex">
          <v-card
            class="mx-1 pt-2 text-center"
            v-for="(dataitem, dataindex) in dataValue"
            :key="dataindex"
            width="300"
            tile
            ><div class="d-flex justify-center px-4">
              <span>{{ dataitem.name }}</span> <v-spacer></v-spacer>
              <template v-if="dataitem.value.length != 0"
                ><v-icon color="error" @click="toDelete('All', dataindex)"
                  >mdi-close-circle</v-icon
                ></template
              >
            </div>
            <template v-if="dataitem.value.length != 0">
              <v-list one-line>
                <template v-for="(item, index) in dataitem.value">
                  <div :key="index">
                    <v-divider v-show="index == 0"></v-divider>
                    <v-list-item
                      ><span>{{ getName(item) }}</span> <v-spacer></v-spacer>
                      <v-icon
                        @click="toDelete('One', dataindex, index)"
                        color="red"
                        >mdi-close</v-icon
                      ></v-list-item
                    >
                    <v-divider></v-divider>
                  </div>
                </template>
              </v-list>
            </template>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card class="mx-1 pt-2 text-center" elevation="1" tile>
              <div class="d-flex justify-center px-4">
                <span>Tags</span> <v-spacer></v-spacer>             
              </div>
              <v-row no-gutters class="px-1">
                <v-col cols="12" sm="4">
                  <v-text-field
                    dense
                    v-model="highlvlTag"
                    outlined
                    label="Highlevel Tag"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="5" class="px-1">
                  <v-select
                    dense
                    class="mx-1"
                    outlined
                    v-model="tagSelected"
                    :items="itemsTags"
                    item-text="name"
                    item-value="id"
                    label="Tags"
                  ></v-select
                ></v-col>
                <v-col cols="12" sm="2" class="d-flex"
                  ><v-btn
                  class="mx-1"
                    color="success"
                    @click="addTag"
                    :loading="loadingTags"
                    :disabled="loadingTags||!validAddTag"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn
                  >
                  <v-tooltip top>
                    <template v-slot:activator="{on}">
                      <v-btn
                      v-on="on"
                    color="info"
                    @click="toaddAdmin"
                    :loading="loadingTags"
                    :disabled="loadingTags||!validAddTag"
                  >
                    <v-icon>mdi-account-star</v-icon>
                  </v-btn>
                     
                    </template>
                     <span>Add as Admin</span>
                  </v-tooltip>
                
                  </v-col
                >
              </v-row>

              <template v-if="tagsVal != null && tagsVal != {}">
                <v-list one-line>
                  <v-list-item class="d-flex px-4">
                    <span class="font-weight-bold">{{ "Highlevels" }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold">{{ "Tags" }}</span>
                  </v-list-item>
                  <template v-for="(value, key) in tagsVal">
                    <div :key="key">
                      <v-list-item class="d-flex px-4">
                        <span>{{ key }}</span> <v-spacer></v-spacer>
                        <span><template v-if="value.asAdmin"><v-icon small color="warning">mdi-star</v-icon></template> {{ showTag(value.id) }}</span>
                        <v-icon
                          class="mx-2"
                          color="red"
                          @click="toDeleteTS('Tag', key)"
                          >mdi-close</v-icon
                        >
                      </v-list-item>
                      <v-divider></v-divider></div></template></v-list
              ></template>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="mx-1 pt-2 text-center" elevation="1" tile>
              <div class="d-flex justify-center px-4">
                <span>Sources</span> <v-spacer></v-spacer>
              </div>
              <v-row no-gutters class="px-1">
                <v-col cols="12" sm="5">
                  <v-text-field
                    dense
                    outlined
                    v-model="highlvlSource"
                    label="Highlevel Source"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="5" class="px-1">
                  <v-select
                    dense
                    v-model="sourceSelected"
                    class="mx-1"
                    outlined
                    :items="itemsSources"
                    item-text="name"
                    item-value="id"
                    label="Sources"
                  ></v-select
                ></v-col>
                <v-col cols="12" sm="1"
                  ><v-btn color="success" @click="addSource" :disabled="!validAddSource||loadingSources" :loading="loadingSources">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <template v-if="sourcesVal != null && sourcesVal != {}">
                <v-list one-line>
                  <v-list-item class="d-flex px-4">
                    <span class="font-weight-bold">{{ "Highlevels" }}</span>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold">{{ "Sources" }}</span>
                  </v-list-item>
                  <template v-for="(value, key) in sourcesVal">
                    <div :key="key">
                      <v-list-item class="d-flex px-4">
                        <span>{{ key }}</span> <v-spacer></v-spacer>
                        <span>{{ showSource(value) }}</span>
                        <v-icon
                          class="mx-2"
                          color="red"
                          @click="toDeleteTS('Source', key)"
                          >mdi-close</v-icon
                        >
                      </v-list-item>
                      <v-divider></v-divider></div></template></v-list
              ></template>
            </v-card>
          </v-col>
        </v-row>
        <div></div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogDelete" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 white--text red lighten-2">
          {{ "Delete " + typedelete }}
        </v-card-title>

        <v-card-text class="body-1 text-center py-3">
          <h4>Are you sure you want to continue with this action?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelDelete()" :disabled="loading">
            Cancel
          </v-btn>
          <template v-if="typedelete == 'All'">
            <v-btn
              color="error"
              text
              @click="deleteAllDay"
              :disabled="loading"
              :loading="loading"
            >
              Confirm
            </v-btn>
          </template>
          <template v-if="typedelete == 'One'">
            <v-btn
              color="error"
              text
              @click="deleteOneAgent"
              :disabled="loading"
              :loading="loading"
            >
              Confirm
            </v-btn>
          </template>
          <template v-if="typedelete == 'Tag'">
            <v-btn
              color="error"
              text
              @click="deleteTag"
              :disabled="loadingTags"
              :loading="loadingTags"
            >
              Confirm
            </v-btn>
          </template>
          <template v-if="typedelete == 'Source'">
            <v-btn
              color="error"
              text
              @click="deleteSource"
              :disabled="loadingSources"
              :loading="loadingSources"
            >
              Confirm
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAdmin" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 white--text info lighten-2">
          {{ "Tag as Admin " }}
        </v-card-title>

        <v-card-text class="body-1 text-center py-3">
          <h4>Are you sure you want to continue with this action?</h4>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAddAdmin()" :disabled="loadingTags">
            Cancel
          </v-btn>
        
          
            <v-btn
              color="info"
              text
              @click="addTag"
              :disabled="loadingTags"
              :loading="loadingTags"
            >
              Confirm
            </v-btn>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      tabSelect: "tab-1",
      byrange: false,
      workers: [],
      employeeUuidts: [],
      daysSelecteds: [],
      itemsTags: [],
      itemsSources: [],
      dayFrom: "",
      dayTo: "",
      days: [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ],
      itemAgents: {
        active: true,
        description: "Matching Agent Config",
        isPublic: false,
        name: "HIGH_LEVEL_MATCH_AGENTS",
        value: "",
      },
      itemTags: {
        active: true,
        description: "Matching Tags and Sources Config",
        isPublic: false,
        name: "HIGH_LEVEL_MATCH_TAGS_SOURCE",
        value: "",
      },
      settingsTagSource: [],
      sourcesVal: {},
      tagsVal: {},
      loadingTags: false,
      loadingSources: false,
      highlvlTag: null,
      asAdmin: false,
      dialogAdmin: false,
      highlvlSource: null,
      tagSelected: null,
      sourceSelected: null,
      selectedkey: null,
      settingsAgents: [],
      objectValueDays: null,
      dataValue: [
        {
          name: "MONDAY",
          value: [],
        },
        {
          name: "TUESDAY",
          value: [],
        },
        {
          name: "WEDNESDAY",
          value: [],
        },
        {
          name: "THURSDAY",
          value: [],
        },
        {
          name: "FRIDAY",
          value: [],
        },
        {
          name: "SATURDAY",
          value: [],
        },
        {
          name: "SUNDAY",
          value: [],
        },
      ],

      selectdataindex: null,
      typedelete: "one",
      selectindex: null,
      dialogDelete: false,
    };
  },
  computed: {
    uuidSettings() {
      if (this.settingsAgents.length == 0) {
        return null;
      }
      return this.settingsAgents[0].uuid;
    },
    uuidSettingsTS() {
      if (this.settingsTagSource.length == 0) {
        return null;
      }
      return this.settingsTagSource[0].uuid;
    },

    validAdd() {
      if (this.employeeUuidts.length == 0) {
        return false;
      }
      if (
        this.byrange &&
        (this.dayTo == "" ||
          this.dayFrom == "" ||
          this.dayTo == null ||
          this.dayFrom == null)
      ) {
        return false;
      }
      if (!this.byrange && this.daysSelecteds.length == 0) {
        return false;
      }
      return true;
    },

    validAddTag(){
      if (this.highlvlTag==null||this.highlvlTag==''||this.highlvlTag==undefined||this.tagSelected==null||this.tagSelected==''||this.tagSelected==undefined) {
        return false;
      }
      return true
    },
    validAddSource(){
      if (this.highlvlSource==null||this.highlvlSource==''||this.highlvlSource==undefined||this.sourceSelected==null||this.sourceSelected==''||this.sourceSelected==undefined) {
        return false;
      }
      return true
    }
  },
  watch: {
    byrange(val) {
      if (val) {
        this.daysSelecteds = [];
      } else {
        this.dayFrom = "";
        this.dayTo = "";
      }
    },
  },
  methods: {
    ...mapActions(["actAddSettings", "actUpdateSettings"]),

    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
    getSettings() {
      this.loading = true;
      getAPI("/setting/list").then((res) => {
        this.loading = false;
        this.settingsAgents = res.data.filter(
          (s) => s.name == "HIGH_LEVEL_MATCH_AGENTS"
        );

        this.settingsTagSource = res.data.filter(
          (rs) => rs.name == "HIGH_LEVEL_MATCH_TAGS_SOURCE"
        );
        const val =
          this.settingsTagSource.length == 0
            ? null
            : JSON.parse(this.settingsTagSource[0].value);
        this.tagsVal = val ? val.tags : {};
        this.sourcesVal = val ? val.sources : {};

        this.objectValueDays =
          this.settingsAgents.length == 0
            ? null
            : JSON.parse(this.settingsAgents[0].value);
        this.dataValue.forEach((dv) => {
          switch (dv.name) {
            case "MONDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.MONDAY
                  ? this.objectValueDays.MONDAY
                  : [];
              break;
            case "TUESDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.TUESDAY
                  ? this.objectValueDays.TUESDAY
                  : [];
              break;
            case "WEDNESDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.WEDNESDAY
                  ? this.objectValueDays.WEDNESDAY
                  : [];
              break;
            case "THURSDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.THURSDAY
                  ? this.objectValueDays.THURSDAY
                  : [];
              break;
            case "FRIDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.FRIDAY
                  ? this.objectValueDays.FRIDAY
                  : [];
              break;
            case "SATURDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.SATURDAY
                  ? this.objectValueDays.SATURDAY
                  : [];
              break;
            case "SUNDAY":
              dv.value =
                this.objectValueDays && this.objectValueDays.SUNDAY
                  ? this.objectValueDays.SUNDAY
                  : [];
              break;
          }
        });
      }).catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
    },
    getEmployees() {
      getAPI("/users/listUsers").then((res) => {
        this.workers = res.data;
      }).catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
    },
    getTags() {
      getAPI("/tags-nom/list").then((res) => {
        this.itemsTags = res.data;
      }).catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
    },
    getSources() {
      getAPI("/source/list").then((res) => {
        this.itemsSources = res.data;
      }).catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      
    },
    getName(uuid) {
      if (this.workers.length == 0) {
        return uuid;
      }
      const x = this.workers.find((w) => w.uuid == uuid);
      return x ? x.fullname : uuid;
    },
    showTag(id) {
      if (this.itemsTags.length == 0) {
        return id;
      }
      const x = this.itemsTags.find((w) => w.id == id);
      return x ? x.name : id;
    },
    showSource(id) {
      if (this.itemsSources.length == 0) {
        return id;
      }
      const x = this.itemsSources.find((w) => w.id == id);
      return x ? x.name : id;
    },

    addConfig() {
      const bodyday = !this.byrange ? this.daysSelecteds : this.byRangeDays();
      for (let index = 0; index < bodyday.length; index++) {
        const element = bodyday[index];
        const dataindex = this.dataValue.findIndex((dv) => dv.name == element);
        const dataConcat = this.employeeUuidts.concat(
          this.dataValue[dataindex].value
        );
        const dataUnic = dataConcat.filter((dc, index) => {
          return dataConcat.indexOf(dc) === index;
        });
        this.dataValue[dataindex].value = dataUnic;
      }
      const body = {
        MONDAY: this.dataValue.find((dv) => dv.name == "MONDAY").value,
        TUESDAY: this.dataValue.find((dv) => dv.name == "TUESDAY").value,
        WEDNESDAY: this.dataValue.find((dv) => dv.name == "WEDNESDAY").value,
        THURSDAY: this.dataValue.find((dv) => dv.name == "THURSDAY").value,
        FRIDAY: this.dataValue.find((dv) => dv.name == "FRIDAY").value,
        SATURDAY: this.dataValue.find((dv) => dv.name == "SATURDAY").value,
        SUNDAY: this.dataValue.find((dv) => dv.name == "SUNDAY").value,
      };

      this.itemAgents.value = JSON.stringify(body);
      this.loading = true;
      if (this.uuidSettings == null) {
        getAPI
          .post("/setting/create/", this.itemAgents)
          .then((res) => {
            this.loading = false;
            this.cancelAdd();
            this.getSettings();
          })
          .catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        getAPI
          .put("/setting/update/" + this.uuidSettings, this.itemAgents)
          .then((res) => {
            this.loading = false;
            this.cancelAdd();
            this.cancelDelete();
            this.getSettings();
          })
          .catch((error) => {
            this.loading = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
    cancelAdd() {
      this.employeeUuidts = [];
      this.byrange = false;
      this.daysSelecteds = [];
      this.selectedkey = null;
      this.sourceSelected = null;
      this.tagSelected = null;
      this.highlvlSource = null;
      this.cancelAddAdmin();
      this.highlvlTag = null;
    },
    byRangeDays() {
      const indexstart = this.days.findIndex((d) => d == this.dayFrom);
      const indexend = this.days.findIndex((d) => d == this.dayTo);
      const arrayD = [];
      for (let index = indexstart; index < indexend + 1; index++) {
        const element = this.days[index];
        arrayD.push(element);
      }

      return arrayD;
    },
    toDelete(type, dataindex, index) {
      this.typedelete = type;
      this.dialogDelete = true;
      this.selectdataindex = dataindex;
      this.selectindex = index ? index : null;
    },
    toDeleteTS(type, key) {
      this.typedelete = type;
      this.dialogDelete = true;
      this.selectedkey = key;
    },
    cancelDelete() {
      this.typedelete = "One";
      this.dialogDelete = false;
      this.selectdataindex = null;
      this.selectindex = null;
      this.selectedkey = null;
    },
    deleteOneAgent() {
      this.dataValue[this.selectdataindex].value.splice(this.selectindex, 1);
      this.addConfig();
    },
    deleteAllDay() {
      this.dataValue[this.selectdataindex].value = [];
      this.addConfig();
    },

    toaddAdmin(){
      this.asAdmin=true;
      this.dialogAdmin=true
    },
    
    cancelAddAdmin(){
      this.asAdmin=false;
      this.dialogAdmin=false
    },


    addTag() {
      this.tagsVal = this.highlvlTag
        ? { ...this.tagsVal, [this.highlvlTag]: {id:this.tagSelected,asAdmin:this.asAdmin} }
        : this.tagsVal;
      let body = Object.assign({}, this.itemTags);
      const values = {
        tags: this.tagsVal,
        sources: this.sourcesVal,
      };
      body.value = JSON.stringify(values);

      this.loadingTags = true;
      if (this.uuidSettingsTS == null) {
        getAPI
          .post("/setting/create/", body)
          .then((res) => {
            this.loadingTags = false;
            this.cancelAdd();
            this.getSettings();
          })
          .catch((error) => {
            this.loadingTags = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        getAPI
          .put("/setting/update/" + this.uuidSettingsTS, body)
          .then((res) => {
            this.loadingTags = false;
            this.cancelAdd();
            this.cancelDelete();
            this.getSettings();
          })
          .catch((error) => {
            this.loadingTags = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
    addSource() {
      this.sourcesVal = this.highlvlSource
        ? { ...this.sourcesVal, [this.highlvlSource]: this.sourceSelected }
        : this.sourcesVal;
      let body = Object.assign({}, this.itemTags);
      const values = {
        tags: this.tagsVal,
        sources: this.sourcesVal,
      };
      body.value = JSON.stringify(values);

      this.loadingSources = true;
      if (this.uuidSettingsTS == null) {
        getAPI
          .post("/setting/create/", body)
          .then((res) => {
            this.loadingSources = false;
            this.cancelAdd();
            this.getSettings();
          })
          .catch((error) => {
            this.loadingSources = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        getAPI
          .put("/setting/update/" + this.uuidSettingsTS, body)
          .then((res) => {
            this.loadingSources = false;
            this.cancelAdd();
            this.cancelDelete();
            this.getSettings();
          })
          .catch((error) => {
            this.loadingSources = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    deleteSource() {
      delete this.sourcesVal[this.selectedkey];
      this.addSource();
    },
    deleteTag(key) {
      delete this.tagsVal[this.selectedkey];
      this.addTag();
    },
  },
  async mounted() {
    await this.getEmployees();
    await this.getSettings();
    await this.getTags();
    await this.getSources();
  },
};
</script>
